






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import "../../src/assets/scss/review-form.scss";

.reply-row {
  margin: 25px 0 0 0;

  strong {
    margin-bottom: 5px;
    display: block
  }
}

.modal_content {

  .post_content {
    max-width: 28rem;
    margin-inline: auto;
    margin-bottom: 1rem;
    position: relative;

    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: flex-start;
      gap: .5rem;
    }

    .post_text_wrapper {
      text-align: left;
      padding: .5rem .8rem;

      p {
        margin: 0;
      }
    }

    .post_image_wrapper {
      border: 1px solid gray;

      .post_image {
        width: 100%;
      }
    }

    .edit_post_button {
      border: none;
      background: #284374;
      color: #fff;
      padding: .3rem 1rem;
      border-radius: 4px;
      cursor: pointer;

      @media (min-width: 768px) {
        position: absolute;
        left: 102%;
        top: 0
      }
    }
  }

  .post_image {
    height: 300px !important;
  }

  .regenrate_buttons_container {
    margin-bottom: .5rem;
    margin-inline: auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .2rem;

    .regenerate_btn {
      margin-inline: .3rem;
      border: none;
      background: #06b050;
      color: #fff;
      padding: .3rem 1.5rem;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .social_media_buttons_container {
    margin-inline: auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .2rem;

    .social_post_btn {
      margin-inline: .3rem;
      border: none;
      background: #284374;
      color: #fff;
      padding: .3rem .7rem;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid black;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-top: 30px;
    margin-left: 30%;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .post {
    background-color: white;
    border: 1px solid black;
    min-height: 430px;
    width: 380px;
    text-align: center;
  }
}

.modal_content {

  .post_content {
    max-width: 28rem;
    margin-inline: auto;
    margin-bottom: 1rem;
    position: relative;

    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: flex-start;
      gap: .5rem;
    }

    .post_text_wrapper {
      padding: .5rem .8rem;

      p {
        margin: 0;
      }
    }

    .post_image_wrapper {
      border: 1px solid gray;

      .post_image {
        width: 100%;
      }
    }

    .edit_post_button {
      border: none;
      background: #284374;
      color: #fff;
      padding: .3rem 1rem;
      border-radius: 4px;
      cursor: pointer;

      @media (min-width: 768px) {
        position: absolute;
        left: 102%;
        top: 0
      }
    }
  }

  .post_image {
    height: 300px !important;
  }

  .regenrate_buttons_container {
    margin-bottom: .5rem;
    margin-inline: auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .2rem;

    .regenerate_btn {
      margin-inline: .3rem;
      border: none;
      background: #06b050;
      color: #fff;
      padding: .3rem 1.5rem;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .social_media_buttons_container {
    margin-inline: auto;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .2rem;

    .social_post_btn {
      margin-inline: .3rem;
      border: none;
      background: #284374;
      color: #fff;
      padding: .3rem .7rem;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid black;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-top: 30px;
    margin-left: 30%;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .post {
    background-color: white;
    border: 1px solid black;
    min-height: 430px;
    width: 380px;
    text-align: center;
  }
}
